.container {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
}

.section-card-left {
  width: 100%;
  /* height: 100px;
  padding: 10px;
  margin: 10px;
  gap: 20px; */

  padding: 0px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

.button-container {
  margin-left: auto; /* Push the button to the right */
}

.pack-order-button {
  width: "fit-content";
}

.section-card-right {
  width: 100%;
  padding: 0px 20px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

.card-title {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 8px;
}

.card-title-body {
  display: flex; /* Use flexbox to create columns */
  justify-content: space-between; /* Distribute columns evenly */
  /* align-items: flex-end; */
}

.card {
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 20px;
}

.card-right {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.coin-container {
  display: flex;
  align-items: left;
}

.coin-img {
  width: 100%;
  margin-right: 3px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.coin-image-container {
  width: 20%; /* Adjust the width of the coin container */
  margin-right: 10px;
}

.container-total {
  display: flex;
  justify-content: flex-end;
}

.total-by-side {
  display: flex;
  gap: 20px;
}

.total-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align content within each inner container to the end */
  padding: 10px;
}

.total-item {
  padding: 5px 0; /* Add padding between individual content items */
}

.bank-logo {
  width: 50px;
  max-height: 100px;
  object-fit: contain;
}

.full-line {
  border: none; /* Remove the default border */
  border-top: 1px solid #ccc; /* Add a top border to create the line */
  margin: 5px 0; /* Add margin for spacing */
}

.line {
  border-bottom: 1px solid #ccc; /* Add a horizontal line */
  margin: 20px 0; /* Add margin for spacing */
}

.grey-text-13 {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: #909090;
}

.grey-text-14 {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #909090;
}

.title-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.title-text-1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.url-text-13 {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: #427ff1;
}

.bottom-space {
  margin-bottom: 10px;
}

.top-space {
  margin-top: 18px;
}

.product-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.bold-text {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  color: #303030;
}

.green-status {
  padding: 6px 16px 6px 16px;
  border-radius: 100px; /* Rounded corners for the container */
  font-size: 12px;
}

h2 {
  margin-top: 0;
}

p {
  margin-bottom: 0;
}

.status {
  padding: 3px 16px 6px 16px;
  border-radius: 100px;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
}

.logs-time {
  margin-right: 10px;
}

.logs-action {
  color: #1890ff !important;
}

.logs-container {
  margin-top: 16px;
}

.logs-container p {
  margin-bottom: 0px;
  color: #303030;
}

.logs-details-container {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 16px;
  margin-top: 8px;
}

.logs-detail-top-column {
  flex: 1;

  .payment-receipt {
    color: #1890ff;
  }
}

.logs-date {
  font-size: 12px;
  color: #505050 !important;
}

.left-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-top: 10px;
  padding: 10px 20px 20px 20px;
}

@media screen and (max-width: 991px) {
  .section-card-right {
    padding-left: 20px;
  }

  .section-card-left {
    padding-right: 20px;
  }
}
